<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>TEAM ADVANCE LLC</span>
  </mat-toolbar-row>
</mat-toolbar>
<div class="p-5 mb-4 bg-light rounded-3">
  <div class="container-fluid py-5">
    <h1 class="display-5 fw-bold">IT Solutions and Consulting</h1>
    <p class="col-md-8 fs-4"></p>
    <button class="btn btn-primary btn-lg" type="button">COMING SOON</button>
  </div>
</div>
<div class="container-fluid">
  <div class="fixed-bottom">
   <div class="container">
    <div class="row">
      <div class="col-12 col-md-3 display-7">
        <h5 class="pb-3">
          Address
        </h5>
        <p class="mbr-text">
          4207 Globe Thistle Dr
          <br>Tampa, FL 33619
        </p>
      </div>
      <div class="col-12 col-md-3 display-7">
        <h5 class="pb-3">
          Contacts
        </h5>
        <p class="mbr-text">
          Email: niekirk.rose@teamadvance.org
          <br>Phone: +1 (813) 693 8573
        </p>
      </div>
    </div>
   </div>
  </div>
</div>